/** @jsx jsx */
import { jsx } from '@emotion/core';

import * as React from 'react';
import Modal from 'react-responsive-modal';
import colors from '../../css/colors';

const overlayStyles = {
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
};

export default function CustomModal({
  children,
  onClose,
  open,
  notCloseOverlay,
  moreStyles,
}) {
  const modalStyles = {
    backgroundColor: '#fff',
    border: `1px solid ${colors.greyBorder}`,
    borderRadius: '5px',
    padding: 40,
    maxWidth: 488,
    width: '100%',
    ...moreStyles,
  };
  return (
    <Modal
      closeOnOverlayClick={!notCloseOverlay}
      open={open !== false}
      onClose={onClose}
      styles={{ modal: modalStyles, overlay: overlayStyles }}
      center={false}
    >
      {children}
    </Modal>
  );
}
