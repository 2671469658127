/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import immer from 'immer';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'semantic-ui-css/semantic.min.css';
import { Table, Icon, Popup, Button, Dropdown, Loader } from 'semantic-ui-react';
import { downloadFiles } from '../AssignedUserReport/PrintDocuments';
import PreviewDoc from '../../orders/order-information/PreviewDoc';
import { TrashCanIcon } from '../../../components/ui/icons';
import CustomModal from '../../ui/CustomModal';
import qs from 'qs';
import Select from 'react-select';
import styled from '@emotion/styled';
import { GetDateInCurrentTimeZone } from '../../../lib/PublicFunctions';
import _ from 'lodash';
import print from 'print-js';


const NassauQueue = () => {
  const [reportData, setReportData] = useState([]);
  const [iFrameOpen, setIFrameOpen] = useState(false);
  const [iFrameUrl, setIframeUrl] = useState('');
  const [printedDocs, setPrintedDocs] = useState([]);
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [statusRequired, setStatusRequired] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [viewingQueue, setViewingQueue] = useState(true);
  const [timestamps, setTimestamps] = useState([]);
  const [timestamp, setTimestamp] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmPrintModal, setConfirmPrintModal] = useState(false);

  const tableRef = useRef(null);
  const docType = 'reqDocuments';

  const totalResults = {
    // display: 'none',
    '@media print': {
      display: 'block',
    },
  };

  const Wrapper = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  `;

  const Item = styled.div`
    display: flex;
    flex-direction: column;
  `;
  const formatDate = (date: string) => moment(date).format('M/D/YYYY');
  const formatDateTime = (date: string) => moment(date).format('M/D/YYYY - h:mma');

  useEffect(() => {
    getNassauQueue();
    populateDropdowns();
  }, []);

  const confirmPrintModalClick = () => {
    setConfirmPrintModal(!confirmPrintModal);
  };

  const download = async () => {
    const documentIds = _.uniq(reportData.map((item) => item.documentId));
    await axios.post('/api/reports/markNassauQueue', documentIds)
    const { data } = await axios.post('/api/documents/ReqDocumentsForPrinting', documentIds);
    print({ printable: data.base64, type: 'pdf', base64: true, showModal: true });
    getNassauQueue()
  };

  const downloadAndMark = async () => {
    await Promise.all([download()]);
  };

  const getNassauQueue = (timestamp: string = null) => {
    setLoading(true);
    const queryString = qs.stringify({
      timestamp: timestamp ?? null,
      isPrinted: timestamp != null ? true : false,
    });
    axios.get(`/api/reports/nassauqueue?${queryString}`).then(({ data }) => {
      setReportData(data);
      setLoading(false);
    });
  };

  const populateDropdowns = () => {
    axios.get('/api/searches/GetSearchStatusTypes').then(({ data }) => {
      setStatuses(data);
    });
    axios.get('/api/users/forassignment').then(({ data }) => {
      setAssignees(data);
    });
    axios.get('/api/reports/GetNassauQueueTimestamps').then(({ data }) => {
      setTimestamps(data);
    });
  };

  const backToPrintQueue = () => {
    setTimestamp(null);
    setViewingQueue(true);
    getNassauQueue();
  };

  const viewHistory = () => {
    setTimestamp(null);
    setViewingQueue(false);
    setReportData([]);
    populateDropdowns();
  };

  const closeModal = () => {
    getNassauQueue();
    setDeleteIndex(-1);
  };

  const renderResults = () => {
    return (
      <Table size="small" compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>HDS#</Table.HeaderCell>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Searches</Table.HeaderCell>
            <Table.HeaderCell>Properties</Table.HeaderCell>
            <Table.HeaderCell>Municipality</Table.HeaderCell>
            <Table.HeaderCell>Submunicipality</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
            {viewingQueue && <Table.HeaderCell></Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {reportData.map((d, idx) => {
            const amountOfProperties = d.properties.length;

            return (
              <Table.Row key={idx} style={{ backgroundColor: d.checkSent ? 'pink' : '' }}>
                <Table.Cell>{`HDS${d['id']}`}</Table.Cell>
                <Table.Cell>
                  {' '}
                  <Link
                    to={{
                      pathname: `/orders/order-information/${d.id}`,
                    }}
                    target="none"
                  >
                    {d['titleNumber']}
                  </Link>
                </Table.Cell>
                <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                <Table.Cell>{_.uniq(d.searchCodes)?.join(', ')}</Table.Cell>
                <Table.Cell>
                  {amountOfProperties === 1 ? d.properties[0]?.address : `${amountOfProperties} properties`}
                </Table.Cell>
                <Table.Cell>{d['municipalityName']}</Table.Cell>
                <Table.Cell>{d['subMunicipalityName']}</Table.Cell>
                <Table.Cell>
                  <Popup
                    key={d.documentId}
                    content={'Request Document'}
                    trigger={
                      <Icon
                        name="print"
                        color={printedDocs?.includes(d.documentId) ? 'green' : 'black'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          const newPrintedDocs = immer(printedDocs, draft => {
                            draft.push(d.documentId);
                          });
                          setPrintedDocs(newPrintedDocs);
                          setIframeUrl(`/api/documents/getDocument/${d.documentId}`);
                          setIFrameOpen(true);
                        }}
                      />
                    }
                  ></Popup>
                </Table.Cell>
                {confirmPrintModal && (
                  <ConfirmPrintModal
                    closeModal={() => {
                      setConfirmPrintModal(false);
                    }}
                    downloadAndMark={downloadAndMark}
                  />
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <React.Fragment>
      {loading && <Loader active size="large" />}
      {!loading && (
        <div css={{ paddingBottom: 32 }}>
          {iFrameOpen && (
            <PreviewDoc onClose={() => setIFrameOpen(false)} open={iFrameOpen} url={iFrameUrl} />
          )}
          <div className="report" ref={tableRef}>
            {viewingQueue && (
              <Wrapper>
                <Button
                  onClick={confirmPrintModalClick}
                  style={{ backgroundColor: '#ff5252', color: 'white' }}
                >
                  Print Documents
                </Button>
                <Button onClick={viewHistory}>View History</Button>
              </Wrapper>
            )}
            {!viewingQueue && (
              <Wrapper>
                <div style={{ height: 40, paddingTop: 10 }}>Print Queue History</div>
                <Item>
                  <Dropdown
                    style={{ minWidth: '220px' }}
                    options={timestamps.map((t, i) => ({
                      key: t,
                      text: formatDateTime(GetDateInCurrentTimeZone(t).format()),
                      value: t,
                    }))}
                    onChange={(_, { value }) => {
                      setTimestamp(value as any), getNassauQueue(value as any);
                    }}
                    placeholder="Select Date"
                    closeOnChange
                    search
                    selection
                    value={timestamp}
                  />
                </Item>
                <Item>
                  <Button onClick={backToPrintQueue}>Back To Print Queue</Button>
                </Item>
                <Item>
                  <Button onClick={download}>Reprint</Button>
                </Item>
              </Wrapper>
            )}

            {renderResults()}
            <div css={totalResults}>{`Total Items: ${reportData.length}`}</div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const ConfirmPrintModal = ({
  closeModal,
  downloadAndMark,
}: {
  closeModal: any;
  downloadAndMark: () => Promise<void>;
}) => {
  return (
    //@ts-ignore
    <CustomModal
      onClose={() => {
        closeModal();
      }}
    
    >
      <div>Are you sure you want to print the queue?</div>
      <div
        css={{
          marginTop: 80,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {' '}
        <Button overRidestyle={{ marginRight: 24 }} onClick={closeModal}>
          No
        </Button>
        <Button
          secondary
          onClick={() => {
            downloadAndMark();
            closeModal();
          }}
        >
          Yes
        </Button>
      </div>
    </CustomModal>
  );
};

export default NassauQueue;
