import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import BuildDepartmentViolation from './UpstateSearches/BuildingDepartmentViolation';
import CertificateOfOccupancyUpstate from './UpstateSearches/CertificateOfOccupancy';
import EmergencyRepair from './UpstateSearches/EmergencyRepair';
import FireViolation from './shared/FireViolation';
import FloodZone from './shared/FloodZone';
import HighwayAndSidewalkViolation from './shared/HighwayAndSidewalkViolation';
import LandmarkSearch from './shared/LandmarkSearch';
import SewerSearch from './UpstateSearches/SewerSearch';
import StreetReportUpstate from './UpstateSearches/StreetReport';
import SurveyLocate from './shared/SurveyLocate';
import AirResource from './NYCSearches/AirResourceSearch';
import EmergencyRepairNYC from './NYCSearches/EmergencyRepair';
import FuelOilPermit from './NYCSearches/FuelOilPermit';
import HealthDepartmentViolations from './NYCSearches/HealthDepartmentViolations';
import CertificateOfOccupancyNYC from './NYCSearches/CertificateOfOccupancy';
import LandmarkViolationSearch from './NYCSearches/LandmarkViolationSearch';
import StreetReportNYC from './NYCSearches/StreetReport';
import VaultTaxSearch from './NYCSearches/VaultTaxSearch';
import TitleReadLetter from './NYCSearches/TitleReadLetter';
import TaxSearch from './TaxSearches/NysTaxSearch/Container';
import WaterSearch from './UpstateSearches/WaterSearch';
import HousingAndBuildingViolation from './NYCSearches/HousingAndBuildingViolation';
import BankruptcySearch from './nameSearches/BankruptcySearch';
import PatriotSearch from './nameSearches/PatriotSearch';
import GasElectric from './shared/GasElectric';
import StateUccSearch from './GeneralSearches/StateUccSearch';
import NycTaxSearch from './TaxSearches/NycTaxSearch/Container';
import NJTaxSearch from './TaxSearches/NJTaxSearch';
import ZoningReport from './shared/ZoningReport';
import MinorPermitAndAlterationSearch from './shared/PermitAndAlterationSearch/PermitAndAlterationSearch';
import EcbSearch from './shared/EcbSearch';
import GreenHome from './shared/GreenHome';
import BuildingPlans from './shared/BuildingPlans';
import { NJTaxSearchProvider } from './TaxSearches/NJTaxSearch/NJTaxSearchContext';
import ErPayoffLetter from './shared/ErPayoffLetter';
import MiscSearch1 from './NYCSearches/MiscSeaches/MiscSearch1';
import MiscSearch2 from './NYCSearches/MiscSeaches/MiscSearch2';
import MiscSearch3 from './NYCSearches/MiscSeaches/MiscSearch3';
import MiscSearch4 from './NYCSearches/MiscSeaches/MiscSearch4';
import NaturalDisaster from './shared/NaturalDisaster';
import TaxServices from './NYCSearches/TaxServices';
import RentStabilizationChargesSearch from './NYCSearches/RentStabilizationChargesSearch';
import { NysTaxSearchProvider } from './TaxSearches/NysTaxSearch/NysTaxSearchContext';
import PropertyCard from './shared/PropertyCardSearch';
import HazardousMaterials from './shared/HazardousMaterialsSearch';
import RpieSearch from './NYCSearches/RpieSearch';
import AssessorCardSearch from './shared/AssessorCardSearch';
import CertificateOfGoodStanding from './shared/CertificateOfGoodStanding';
import FranchiseTax from './shared/FranchiseTax';
import ArticlesOfOrganization from './shared/ArticlesOfOrganization';
import DocRetrieval from './shared/DocRetrieval';
import VacantRegistryFees from './shared/VacantRegistryFees';
import ZoningComplianceLetter from './shared/ZoningComplianceLetter';


const SearchFormsRoutes = ({ match }) => (
  <div>
    <Route
      path={`${match.path}/upstate/building-dept-violation`}
      component={withRouter(BuildDepartmentViolation)}
    />
    <Route
      path={`${match.path}/upstate/certificate-of-occupancy`}
      component={withRouter(CertificateOfOccupancyUpstate)}
    />
    <Route path={`${match.path}/upstate/emergency-repair`} component={withRouter(EmergencyRepair)} />
    <Route path={`${match.path}/upstate/fire-violation`} component={withRouter(FireViolation)} />
    <Route path={`${match.path}/upstate/flood-zone`} component={withRouter(FloodZone)} />
    <Route
      path={`${match.path}/upstate/highway-sidewalk-violation`}
      component={withRouter(HighwayAndSidewalkViolation)}
    />
    <Route path={`${match.path}/upstate/landmark-search`} component={withRouter(LandmarkSearch)} />
    <Route path={`${match.path}/upstate/sewer-search`} component={withRouter(SewerSearch)} />
    <Route path={`${match.path}/upstate/street-report`} component={withRouter(StreetReportUpstate)} />
    <Route path={`${match.path}/upstate/survey-locate`} component={withRouter(SurveyLocate)} />
    <Route path={`${match.path}/upstate/water-search`} component={withRouter(WaterSearch)} />
    <Route path={`${match.path}/upstate/green-home`} component={withRouter(GreenHome)} />

    <Route path={`${match.path}/nyc/air-resource`} component={withRouter(AirResource)} />
    <Route
      path={`${match.path}/nyc/building-dept-violation`}
      component={withRouter(HousingAndBuildingViolation)}
    />
    <Route
      path={`${match.path}/nyc/certificate-of-occupancy`}
      component={withRouter(CertificateOfOccupancyNYC)}
    />
    <Route path={`${match.path}/nyc/emergency-repair`} component={withRouter(EmergencyRepairNYC)} />
    <Route path={`${match.path}/nyc/fire-violation`} component={withRouter(FireViolation)} />
    <Route path={`${match.path}/nyc/flood-zone`} component={withRouter(FloodZone)} />
    <Route path={`${match.path}/nj/flood-zone`} component={withRouter(FloodZone)} />
    <Route path={`${match.path}/nyc/fuel-oil-permit`} component={withRouter(FuelOilPermit)} />
    <Route
      path={`${match.path}/nyc/health-department-violations`}
      component={withRouter(HealthDepartmentViolations)}
    />
    <Route
      path={`${match.path}/nyc/highway-sidewalk-violation`}
      component={withRouter(HighwayAndSidewalkViolation)}
    />
    <Route path={`${match.path}/nyc/landmark-search`} component={withRouter(LandmarkSearch)} />
    <Route
      path={`${match.path}/nyc/landmark-violation-search`}
      component={withRouter(LandmarkViolationSearch)}
    />
    <Route path={`${match.path}/nyc/survey-locate`} component={withRouter(SurveyLocate)} />
    <Route path={`${match.path}/nyc/street-report`} component={withRouter(StreetReportNYC)} />
    <Route path={`${match.path}/nyc/vault-tax-search`} component={withRouter(VaultTaxSearch)} />
    <Route path={`${match.path}/nyc/green-home`} component={withRouter(GreenHome)} />
    <Route path={`${match.path}/finalWater`} component={withRouter(TitleReadLetter)} />
    <Route path={`${match.path}/buildingPlans`} component={withRouter(BuildingPlans)} />

    <Route path={`${match.path}/bankruptcy-search`} component={withRouter(BankruptcySearch)} />
    <Route path={`${match.path}/patriot-search`} component={withRouter(PatriotSearch)} />
    <Route path={`${match.path}/ge-search`} component={withRouter(GasElectric)} />
    <Route path={`${match.path}/state-ucc-search`} component={withRouter(StateUccSearch)} />
    <Route path={`${match.path}/zoning-report`} component={withRouter(ZoningReport)} />
    <Route path={`${match.path}/zoning-compliance-letter`} component={withRouter(ZoningComplianceLetter)} />
    <Route path={`${match.path}/permit-alteration`} component={withRouter(MinorPermitAndAlterationSearch)} />
    <Route path={`${match.path}/ecbSearch`} component={withRouter(EcbSearch)} />
    <Route path={`${match.path}/ErPayoffLetter`} component={withRouter(ErPayoffLetter)} />
    <Route path={`${match.path}/miscSearch1`} component={withRouter(MiscSearch1)} />
    <Route path={`${match.path}/miscSearch2`} component={withRouter(MiscSearch2)} />
    <Route path={`${match.path}/miscSearch3`} component={withRouter(MiscSearch3)} />
    <Route path={`${match.path}/miscSearch4`} component={withRouter(MiscSearch4)} />
    <Route path={`${match.path}/NaturalDisaster`} component={withRouter(NaturalDisaster)} />
    <Route path={`${match.path}/HazardousMaterials`} component={withRouter(HazardousMaterials)} />
    <Route path={`${match.path}/AssessorCardSearch`} component={withRouter(AssessorCardSearch)} />
    <Route path={`${match.path}/certificate-of-good-standing`} component={withRouter(CertificateOfGoodStanding)} />
    <Route path={`${match.path}/franchise-tax`} component={withRouter(FranchiseTax)} />
    <Route path={`${match.path}/articles-of-organization`} component={withRouter(ArticlesOfOrganization)} />
    <Route path={`${match.path}/doc-retrieval`} component={withRouter(DocRetrieval)} />
    <Route
      path={`${match.path}/upstate/tax-search`}
      render={() => (
        <NysTaxSearchProvider>
          <TaxSearch />
        </NysTaxSearchProvider>
      )}
    />
    <Route path={`${match.path}/nyc/tax-search`} component={withRouter(NycTaxSearch)} />
    <Route path={`${match.path}/tax-services`} component={withRouter(TaxServices)} />
    <Route path={`${match.path}/rent-search`} component={withRouter(RentStabilizationChargesSearch)} />
    <Route path={`${match.path}/RpieSearch`} component={withRouter(RpieSearch)} />
    <Route path={`${match.path}/propertyCard`} component={withRouter(PropertyCard)} />
    <Route path={`${match.path}/VacantRegistryFees`} component={withRouter(VacantRegistryFees)} />
    <Route
      path={`${match.path}/nj/tax-search`}
      render={() => (
        <NJTaxSearchProvider>
          <NJTaxSearch />
        </NJTaxSearchProvider>
      )}
    />
  </div>
);

export default SearchFormsRoutes;
