import axios from 'axios';
import { reactSelectCustomStyles } from 'components/ui/ReactSelectBaseStyles';
import React, { useEffect } from 'react';
import Select from 'react-select';

interface IProps {
  onChange: (county: DropdownOption) => void;
  counties?: CountyReturn[];
  height?: number;
  width?: number;
}

interface CountyReturn {
  id: number;
  name: string;
  stateCode: string;
}

const CountyDropdown = (props: IProps) => {
  const [counties, setCounties] = React.useState([]);

  const handleChange = (selectedOption: DropdownOption) => {
    props.onChange(selectedOption);
  };

  const getOptions = (data: CountyReturn[]) =>
    data.map((county) => ({
      value: county.id,
      label: `${county.name} - ${county.stateCode}`,
    }));

  useEffect(() => {
    if (props.counties) {
      setCounties(getOptions(props.counties));
    } else {
      axios.get<CountyReturn[]>('/api/municipalities/GetCountyOnlyNames').then(({ data }) => {
        setCounties(getOptions(data));
      });
    }
  }, [props.counties]);
  return (
    <Select
      styles={reactSelectCustomStyles(props.width, props.height)}
      placeholder="Select a County"
      onChange={handleChange}
      options={counties}
    />
  );
};

export default CountyDropdown;
