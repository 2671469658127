/** @jsx jsx */
import { jsx } from '@emotion/core';
import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import Button from '../ui/Button';
import { DatePicker } from 'react-widgets';
import moment from 'moment';
import TextField from '../ui/TextField';
import 'semantic-ui-css/semantic.min.css';
import { Grid, GridColumn, Table } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import CountyDropdown from 'lib/CountyDropdown';
import AsyncSelect from 'react-select/async';
import qs from 'qs';
import sortBy from 'lodash.sortby';

const customStyles = {
  container: provided => ({
    ...provided,
    width: 255,
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused && '1px solid #ff5252',
    border: state.isFocused ? '1px solid #ff5252' : '1px solid #e5e5ea',
    //height: 48,
    '&:hover': {
      border: state.isFocused && '1px solid #ff5252',
    },
  }),
};

const fromStyles = {
  width: 300,
  display: 'inline-block',
};
const toStyles = {
  width: 300,
  marginLeft: 16,
  display: 'inline-block',
};

export default function TownCountyReport() {
  const [reportData, setReportData] = useState([]);
  const [showReport, setShowReport] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(0, 'days').format()));
  const [toDate, setToDate] = useState(new Date());
  const [county, setCounty] = useState({});
  const [municipality, setMunicipality] = useState();
  const [subMunicipality, setSubMunicipality] = useState();
  const [loading, setLoading] = useState(false);

  const formatDate = date => moment(date).format('M/D/YYYY');

  async function handleSubmitClick() {
    setLoading(true);
    const { data } = await axios.get(
      `/api/reports/towncountyreport?fromDate=${formatDate(fromDate)}&toDate=${formatDate(
        toDate
      )}&countyId=${county}&municipalityId=${municipality?.value}&subMunicipalityId=${subMunicipality?.value}`
    );
    setReportData(data);

    setLoading(false);
    setShowReport(true);
  }

  const handleMuniChange = item => {
    if (item.isSubMuni) {
      setSubMunicipality(item);
      setMunicipality(null);
    } else {
      setSubMunicipality(null);
      setMunicipality(item);
    }
  };
  const onMunicipalitiesTextChange = async value => {
    const response = await axios.get(`/api/property/GetCityOptions`, {
      params: {
        searchValue: (value || '').trim(),
        //locationTypeId: locationId || null,
      },

      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
    const filteredOutHamlets = response.data.filter(m => !m.hamletId);
    const sorted = sortBy(filteredOutHamlets, [
      m => (m.subMunicipalityType ? m.subMunicipalityType : ''),
      m => m.subMunicipality || m.municipality,
    ]);
    return sorted.map(m => {
      return m.subMunicipalityId > 0
        ? {
            value: m.subMunicipalityId,
            label: `${m.subMunicipalityType} of ${m.subMunicipality}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: true,
          }
        : m.hamletId > 0
        ? {
            value: m.hamletId,
            label: `Hamlet of ${m.hamletName}, ${m.municipalityType} of ${m.municipality}`,
            isSubMuni: false,
            isHamlet: true,
          }
        : {
            value: m.municipalityId,
            label: `${m.municipalityType} of ${m.municipality}, County of ${m.county}`,
            isSubMuni: false,
          };
    });
  };

  const tableRef = useRef(null);

  const renderResults = () => {
    const listCounties = reportData.reduce(
      (prev, d) => (prev.includes(d.county) ? prev : prev.concat([d.county])),
      []
    );

    const theStructure = listCounties.map(c => {
      const towns = reportData
        .filter(d => d.county === c && d.town)
        .reduce((prev, d) => (prev.includes(d.town) ? prev : prev.concat([d.town])), []);
      const cities = reportData
        .filter(d => d.county === c && d.city)
        .reduce((prev, d) => (prev.includes(d.city) ? prev : prev.concat([d.city])), []);
      const boroughs = reportData
        .filter(d => d.county === c && d.borough)
        .reduce((prev, d) => (prev.includes(d.borough) ? prev : prev.concat([d.borough])), []);
      const townships = reportData
        .filter(d => d.county === c && d.township)
        .reduce((prev, d) => (prev.includes(d.township) ? prev : prev.concat([d.township])), []);
      return {
        county: c,
        records: {
          towns: {
            type: 'Town',
            list: towns,
          },
          cities: {
            type: 'City',
            list: cities,
          },
          boroughs: {
            type: 'Borough',
            list: boroughs,
          },
          townships: {
            type: 'Township',
            list: townships,
          },
        },
      };
    });

    return (
      <Table size="small" compact className="tableTwelve">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Title Number</Table.HeaderCell>
            <Table.HeaderCell>Order Date</Table.HeaderCell>
            <Table.HeaderCell>Buyer</Table.HeaderCell>
            <Table.HeaderCell>Seller</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>County</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>Town</Table.HeaderCell>
            <Table.HeaderCell>Borough</Table.HeaderCell>
            <Table.HeaderCell>Township</Table.HeaderCell>
            <Table.HeaderCell>Village</Table.HeaderCell>
            <Table.HeaderCell>Section</Table.HeaderCell>
            <Table.HeaderCell>Block</Table.HeaderCell>
            <Table.HeaderCell>Lot</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {theStructure.map((struc, i) => {
            return (
              // <div css={{ marginTop: 8 }} key={struc.county}>
              <React.Fragment key={i}>
                <Table.Row key={struc.county}>
                  <Table.Cell colSpan={16} style={{ border: '2px solid black' }}>
                    <h4>
                      County: <span css={{ fontWeight: 'bold', margin: 0 }}>{struc.county}</span>
                    </h4>
                  </Table.Cell>
                </Table.Row>
                {Object.keys(struc.records).map((k, i2) => {
                  return (
                    <React.Fragment key={`${i} ${i2}`}>
                      {struc.records[k].list.map((tc, i3) => {
                        return (
                          <React.Fragment key={`${i} ${i2} ${i3}`}>
                            <Table.Row key={tc}>
                              <Table.Cell colOffset={1} colSpan={15}>
                                <h5>
                                  {`${struc.records[k].type}: `}
                                  <span css={{ fontWeight: 'bold' }}>{tc}</span>
                                </h5>
                              </Table.Cell>
                            </Table.Row>
                            {reportData
                              .filter(d => d[struc.records[k].type.toLowerCase()] === tc)
                              .map((d, idx) => {
                                return (
                                  <Table.Row key={idx}>
                                    <Table.Cell>
                                      <Link
                                        to={{
                                          pathname: `/orders/order-information/${d.orderId}`,
                                        }}
                                        target="none"
                                      >
                                        {d['orderNumber']}
                                      </Link>
                                    </Table.Cell>
                                    <Table.Cell>{formatDate(d['orderDate'])}</Table.Cell>
                                    <Table.Cell>{d['buyer']}</Table.Cell>
                                    <Table.Cell>{d['seller']}</Table.Cell>
                                    <Table.Cell>{d['address']}</Table.Cell>
                                    <Table.Cell>{d['county']}</Table.Cell>
                                    <Table.Cell>{d['city']}</Table.Cell>
                                    <Table.Cell>{d['town']}</Table.Cell>
                                    <Table.Cell>{d['borough']}</Table.Cell>
                                    <Table.Cell>{d['township']}</Table.Cell>
                                    <Table.Cell>{d['village']}</Table.Cell>
                                    <Table.Cell>{d['section']}</Table.Cell>
                                    <Table.Cell>{d['block']}</Table.Cell>
                                    <Table.Cell>{d['lot']}</Table.Cell>
                                  </Table.Row>
                                );
                              })}
                          </React.Fragment>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </Table.Body>
      </Table>
    );
  };

  return (
    <div css={{ paddingBottom: 32 }}>
      <div className="report">
        <style>
          {`.tableTwelve td {max-width: 160px;overflow: hidden; text-overflow: ellipsis;white-space: nowrap;}`}
        </style>
        <h1 css={{ marginBottom: 16 }}>Town/County Report</h1>
        <Grid>
          <GridRow>
            <GridColumn width={8}>
              <div style={fromStyles}>
                <label>From</label>
                <DatePicker format="M/D/YYYY" value={fromDate} time={false} onChange={e => setFromDate(e)} />
              </div>
              <div style={toStyles}>
                <label>To</label>
                <DatePicker format="M/D/YYYY" value={toDate} time={false} onChange={e => setToDate(e)} />
              </div>
            </GridColumn>
          </GridRow>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>County</label>
            <CountyDropdown onChange={selection => setCounty(selection?.value)} height={33} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', zIndex: 500 }}>
            <div>Municipality/Sub Municipality</div>
            <AsyncSelect
              value={municipality || subMunicipality || null}
              cacheOptions
              loadOptions={onMunicipalitiesTextChange}
              styles={customStyles}
              isClearable
              placeholder="Start Typing..."
              onChange={item => {
                handleMuniChange(item);
              }}
            />
          </div>

          <Button overRidestyle={{marginTop: 16}} loadingStatus={loading} onClick={() => handleSubmitClick()}>
            Submit
          </Button>
        </Grid>
        {showReport && !reportData.length && (
          <Grid>
            <GridRow>No items found for given time period.</GridRow>
          </Grid>
        )}
        {Boolean(showReport && reportData.length) && (
          <Grid>
            <GridRow>
              <ReactToPrint
                trigger={() => <Button secondary>Print</Button>}
                content={() => tableRef.current}
              />
            </GridRow>
            <div ref={tableRef}>
              <GridRow>{`Order Date: ${formatDate(fromDate)} through ${formatDate(toDate)}`}</GridRow>
              {county?.name ? <GridRow>{`Grouped by County: ${county.name}`}</GridRow> : ''}
              {renderResults()}
            </div>
          </Grid>
        )}
      </div>
    </div>
  );
}
